import { useGetList, Title } from 'react-admin';
import GuacamoleInfo from '../Components/GuacamoleInfo';
import { CircularProgress, Typography, Box } from '@mui/material';
import TveTitle from '../Components/TveTitle';
import { styled } from '@mui/material/styles';

const Wrapper = styled(Box)({
  display: 'grid',
  paddingTop: '20px',
});

const TveStudentDashboard = () => {
  const { data, total, isLoading, error } = useGetList('myenvironments', {
    pagination: { page: 1, perPage: 0 },
    sort: { field: 'tveClass.name', order: 'ASC' },
  });
  if (isLoading) {
    return <CircularProgress />;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  if (total === 0) {
    return (
      <>
        <Title title={<TveTitle pageName="Student Dashboard" />} />
        <Wrapper>
          <Typography variant="h5">You are not currently enrolled in a class</Typography>
        </Wrapper>
      </>
    );
  }
  return (
    <>
      <Title title={<TveTitle pageName="Student Dashboard" />} />
      {data.map((record) => (
        <GuacamoleInfo record={record} key={record.id} />
      ))}
      <div>
        {data.length === 1 && <div>{data.length} item</div>}
        {data.length > 1 && data.length === total && <div>{data.length} items</div>}
        {data.length !== total && (
          <div>
            showing {data.length} of {total} items
          </div>
        )}
      </div>
    </>
  );
};

export default TveStudentDashboard;
