import FinalIcon    from '@mui/icons-material/CloudOutlined'
import CreateIcon    from '@mui/icons-material/CloudOutlined'
import StartIcon     from '@mui/icons-material/CloudUploadOutlined'
import PauseIcon     from '@mui/icons-material/CloudDownloadOutlined'
import DeleteIcon    from '@mui/icons-material/CloudOffOutlined'
import DropIcon      from '@mui/icons-material/AssignmentReturn'
import CompleteIcon  from '@mui/icons-material/AssignmentTurnedIn'
import ExtendIcon    from '@mui/icons-material/Update'
import StoredUser from '../System/StoredUser';

const FINAL   = 'FINAL'
const CREATE   = 'CREATE'
const START    = 'START'
const PAUSE    = 'PAUSE'
const DELETE   = 'DELETE'
const DROP     = 'DROP'
const COMPLETE = 'COMPLETE'
const EXTEND   = 'EXTEND'

const canModifyStudent = student => ["Not Created", "Deleted"].includes(student.tveStatus.name)
const canDeleteStudent = student => (
    (
        ["Not Created"].includes(student.tveStatus.name) &&
        ["INSTRUCTOR"].includes(new StoredUser().getPermissions())
    ) || (
        ["Not Created", "Deleted"].includes(student.tveStatus.name) && 
        ["ADMIN", "DEVELOPER"].includes(new StoredUser().getPermissions())
    )
)
export {
    FINAL,
    FinalIcon,
    CREATE,
    CreateIcon,
    START,
    StartIcon,
    PAUSE,
    PauseIcon,
    DELETE,
    DeleteIcon,
    DROP,
    DropIcon,
    COMPLETE,
    CompleteIcon,
    EXTEND,
    ExtendIcon,

    canModifyStudent,
    canDeleteStudent
}

