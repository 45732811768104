import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, useRecordContext } from 'react-admin';
import ListIcon from '@mui/icons-material/List';
import { withLogging } from '../System/LoggingUtils';

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e) => e.stopPropagation();

const TestingButton = ({ label = 'Stack Reporting', icon = <ListIcon />, ...rest }) => {
  // const record = useRecordContext();
  return (
    <Button
      component={Link}
      to={`/administrationreporting`}
      label={label}
      variant="contained"
      onClick={stopPropagation}
      {...rest}
    >
      {icon}
    </Button>
  );
};
TestingButton.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.string,
  record: PropTypes.object,
};

export default withLogging(TestingButton);
