import React from 'react';
import {
  InfiniteList,
  InfinitePagination,
  useListContext,
  TextField,
  DatagridConfigurable,
  SelectColumnsButton,
  CreateButton,
  TopToolbar,
  BulkDeleteButton,
} from 'react-admin';
import TveTitle from '../Components/TveTitle';
import { Box, Card, Typography } from '@mui/material';

const CustomPagination = () => {
  const { total } = useListContext();
  return (
    <>
      <InfinitePagination />
      {total > 0 && (
        <Box position="sticky" bottom={0} textAlign="center">
          <Card
            elevation={2}
            sx={{ px: 2, py: 1, mb: 1, display: 'inline-block', backgroundColor: 'lightblue' }}
          >
            <Typography variant="body2">{total} stack versions</Typography>
          </Card>
        </Box>
      )}
    </>
  );
};

const TveCanesVersionListActions = () => (
  <TopToolbar>
    <CreateButton label="add stack version" variant="contained" />
    <SelectColumnsButton variant="contained" />
  </TopToolbar>
);

const BulkActionButtons = () => (
  <>
    <BulkDeleteButton
      sx={{
        color: 'white',
        backgroundColor: 'red',
        padding: '6px 11px',
        '&:hover': {
          backgroundColor: '#0066CC',
        },
      }}
    />
  </>
);

const TveCanesVersionList = () => (
  <InfiniteList
    pagination={<CustomPagination />}
    title={<TveTitle pageName="Stack Versions" />}
    perPage={25}
    actions={<TveCanesVersionListActions />}
  >
    <DatagridConfigurable
      bulkActionButtons={<BulkActionButtons />}
      rowClick="edit"
      omit={['totalClassesUsingThisVersion', 'canesVersionId']}
    >
      <TextField label="Stack Version" source="name" />
      <TextField source="description" />
      <TextField source="track" />
      <TextField label="Hardware Version" source="hardwareVersion" />
      <TextField label="Software Version" source="softwareVersion" />
      <TextField label="Has Performance Test" source="hasPerformanceTest" />
      <TextField label="Active" source="active" />
      <TextField label="# Stacks Using" source="totalClassesUsingThisVersion" />
      <TextField label="Id" source="canesVersionId" />
    </DatagridConfigurable>
  </InfiniteList>
);
export default TveCanesVersionList;
