import React from 'react';
import {
  InfiniteList,
  InfinitePagination,
  useListContext,
  DatagridConfigurable,
  TextField,
  TextInput,
  DateField,
  DateInput,
  downloadCSV,
  FunctionField,
  BooleanInput,
  ExportButton,
  TopToolbar,
  SelectColumnsButton,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import TveTitle from '../Components/TveTitle';
import CreateTveButton from '../Components/CreateTveButton';
import StartTveButton from '../Components/StartTveButton';
import PauseTveButton from '../Components/PauseTveButton';
import DeleteTveButton from '../Components/DeleteTveButton';
import DropStudentButton from '../Components/DropStudentButton';
import CompleteStudentTrainingButton from '../Components/CompleteStudentTrainingButton';
import TveStatus from '../Components/TveStatus';
import { Box, Card, Typography } from '@mui/material';

const exporter = (props) => {
  const tveClassAppUserListForExport = props.map((tveClassAppUser) => {
    // Omit all the fields inside the curly brackets
    const {
      id,
      ship,
      canesVersion,
      student,
      tveClass,
      appUserTrainingStatus,
      tveStatus,
      guacProxyUrl,
      tveStackId,
      tveStackName,
      tvePauseTime,
      currentExtend,
      totalExtend,
      lastExtendTime,
      scheduleActive,
      currentBootupGroupNames,
      nextBootupGroupNames,
      bootupStartTime,
      bootupWaitSeconds,
      createTveAppUser,
      createTveAppUserId,
      tveStartupTime,
      guacUrl,
      guacUsername,
      guacPassword,
      stackCommitHash,
      studentName,
      tveClassAppUserId,
      createdTime,
      stackUserType,
      trainingType,
      finalTriggered,
      ...tveClassAppUserListForExport
    } = tveClassAppUser;

    // Add fields
    tveClassAppUserListForExport.ClassId = tveClass.tveClassId;
    tveClassAppUserListForExport.StudentId = tveClassAppUser.tveClassAppUserId;
    tveClassAppUserListForExport.ClassName = tveClass.name;
    tveClassAppUserListForExport.Location = tveClass.tveTrainingLocation?.displayName;
    tveClassAppUserListForExport.Instructor = tveClass.instructor.appUserAlias;
    tveClassAppUserListForExport.StackCreatedBy = tveClassAppUser.createTveAppUser?.username;
    tveClassAppUserListForExport.Student = tveClassAppUser.studentName;
    tveClassAppUserListForExport.StackUserType = stackUserType;
    tveClassAppUserListForExport.StudentUserName = tveClassAppUser.student?.username;
    tveClassAppUserListForExport.CanesVersion = canesVersion.name;
    tveClassAppUserListForExport.TrainingType = trainingType;
    tveClassAppUserListForExport.finalTriggered = finalTriggered;
    tveClassAppUserListForExport.TveStatus = tveStatus.name;
    tveClassAppUserListForExport.ClassType = tveClass.classType;
    tveClassAppUserListForExport.CreatedTime = createdTime;
    tveClassAppUserListForExport.ClassStartDate_Timestamp = tveClass.startDate;
    tveClassAppUserListForExport.ClassEndDate_Timestamp = tveClass.endDate;

    return tveClassAppUserListForExport;
  });

  jsonExport(tveClassAppUserListForExport, {}, (_err, csv) => {
    downloadCSV(csv, 'TveClassAppUsers');
  });
};

const CustomPagination = () => {
  const { total } = useListContext();
  return (
    <>
      <InfinitePagination />
      {total > 0 && (
        <Box position="sticky" bottom={0} textAlign="center">
          <Card
            elevation={2}
            sx={{ px: 2, py: 1, mb: 1, display: 'inline-block', backgroundColor: 'lightblue' }}
          >
            <Typography variant="body2">{total} stacks</Typography>
          </Card>
        </Box>
      )}
    </>
  );
};

const AdministrationListActions = () => (
  <TopToolbar>
    <ExportButton
      sort={{ field: 'ClassId', order: 'DESC' }}
      exporter={exporter}
      maxResults={5000}
      variant="contained"
    />
    <SelectColumnsButton variant="contained" />
  </TopToolbar>
);

const BulkActionButtons = () => (
  <>
    <CreateTveButton />
    <StartTveButton />
    <PauseTveButton />
    <DeleteTveButton />
    <DropStudentButton />
    <CompleteStudentTrainingButton />
  </>
);

const studentFilters = [
  <BooleanInput sx={{ pl: 3, pb: 1 }} label="Include Deleted" source="includeDeleted" alwaysOn />,
  <BooleanInput
    sx={{ pl: 0, pb: 1 }}
    label="Hide Non-Instructing Classes"
    source="hideNonInstructing"
    alwaysOn
  />,
  <TextInput label="Filter by Class Id" source="tveClass.tveClassId" alwaysOn />,
  <TextInput label="Student Name" source="studentName" resettable alwaysOn />,
  <TextInput label="Stack User Type" source="stackUserType" alwaysOn />,
  <TextInput label="Student CAC" source="student.username" alwaysOn />,
  <TextInput label="Instructor / Created By" source="createTveAppUser.username" alwaysOn />,
  <TextInput label="Stack Version" source="canesVersion.name" alwaysOn />,
  <TextInput label="TVE Status" source="tveStatus.name" alwaysOn />,
  <DateInput label="Created Time" source="createdTime" alwaysOn />,
  <DateInput label="Class Start Date From" source="tveClass.startDateFrom" alwaysOn />,
  <DateInput label="Class Start Date To" source="tveClass.startDateTo" alwaysOn />,
  <DateInput label="Class End Date From" source="tveClass.endDateFrom" alwaysOn />,
  <DateInput label="Class End Date To" source="tveClass.endDateTo" alwaysOn />,
];

const timestampToDate = (timestampstring) => {
  return new Date(timestampstring).toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    timeZone: 'UTC',
  });
};

const translateTrainingType = (trainingType) => {
  if (trainingType === 'CS') return 'Core + Strand';
  if (trainingType === 'S') return 'Strand Only';
  if (trainingType === 'C') return 'Core Only';
  if (trainingType === 'I') return 'Instructor';
};

const translateClassType = (classtype) => {
  if (classtype === 'I') return 'Instructing';
  if (classtype === 'N') return 'Non-Instructing';
};

const TveClassAppUserList = () => (
  <InfiniteList
    pagination={<CustomPagination />}
    title={<TveTitle pageName="Stack Reporting" />}
    sort={{ field: 'tveStatus.name', order: 'DESC' }}
    filters={studentFilters}
    filterDefaultValues={{ includeDeleted: false, hideNonInstructing: false }}
    actions={<AdministrationListActions />}
    resource="administration"
  >
    <DatagridConfigurable
      // bulkActionButtons={<BulkActionButtons />}
      bulkActionButtons={false}
    >
      <TextField source="tveClass.tveClassId" label="Class Id" />
      <TextField source="id" label="Student Id" />
      <TextField source="tveClass.name" label="Class Name" />
      <TextField source="tveClass.tveTrainingLocation.location" label="Training Location" />
      <TextField source="studentName" label="Student Name" />
      <TextField source="stackUserType" label="Stack User Type" />
      <FunctionField
        label="Student CAC"
        render={(record) =>
          record.student?.username === undefined ? `` : `${record.student?.username.slice(0, 17)}`
        }
        sortBy="student.username"
      />
      <TextField source="createTveAppUser.username" label="Instructor / Created By" />
      <FunctionField
        label="Stack Version"
        render={(record) => `${record.canesVersion.name.slice(7)}`}
        sortBy="canesVersion.name"
      />
      {/* <FunctionField render={record => `${record.tveClass.instructor.appUserAlias.slice(0,17)}`} label="Instructor"  /> */}
      <FunctionField
        label="Training Type"
        render={(record) => translateTrainingType(record.trainingType)}
        sortBy="trainingType"
      />
      <TveStatus source="tveStatus.name" label="TVE Status" refreshInterval={5000} />
      <FunctionField
        label="Class Type"
        render={(record) => translateClassType(record.tveClass.classType)}
        sortBy="tveClass.classType"
      />
      <TextField source="tveStartupTime" label="Start Time" />
      <TextField source="tvePauseTime" label="Pause Time" />
      <DateField source="createdTime" label="Created Time" showTime />
      <FunctionField
        label="Class Start Date"
        render={(record) => `${timestampToDate(record.tveClass.startDate)}`}
        sortBy="tveClass.startDate"
      />
      <FunctionField
        label="Class End Date"
        render={(record) => `${timestampToDate(record.tveClass.endDate)}`}
        sortBy="tveClass.endDate"
      />
    </DatagridConfigurable>
  </InfiniteList>
);

export default TveClassAppUserList;
