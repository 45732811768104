import React from 'react';
import SchedulerButton from './SchedulerButton';
import { UNSCHEDULE_STARTUP } from '../Config/ScheduleActions';
import { UnscheduleIcon } from '../Config/ScheduleActions';

const UnscheduleStartupTveButton = (props) => (
  <SchedulerButton
    label="Disable Auto-Start"
    color="primary"
    icon={<UnscheduleIcon />}
    action={UNSCHEDULE_STARTUP}
    {...props}
  />
);

export default UnscheduleStartupTveButton;
