import React from 'react';
import {
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  DeleteWithConfirmButton,
  TextField,
  TextInput,
  ReferenceInput,
  SelectInput,
  Labeled,
  required,
  useRecordContext,
  FormDataConsumer,
  email,
  number,
} from 'react-admin';
import { ROLES } from '../Config/AuthProvider';
import BackButton from '../Components/BackButton';
import { INSTRUCTOR_TRACKS } from '../Config/AuthProvider';

const UsersEditTitle = () => {
  const record = useRecordContext();
  return <span>Edit {record ? ` User: ${record.username}` : ''}</span>;
};

const CustomToolbar = () => {
  const record = useRecordContext();
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton />
      <DeleteWithConfirmButton
        confirmTitle={`Delete Student: ${record.username}`}
        confirmContent={`Are you sure you want to delete ${record.username}?`}
        variant="contained"
        sx={{
          color: 'white',
          backgroundColor: 'red',
          padding: '6px 11px',
          '&:hover': {
            backgroundColor: '#0066CC',
          },
        }}
      />
    </Toolbar>
  );
};

const isRoleStudent = (role) => {
  return role === 'STUDENT' ? true : false;
};

const UsersEdit = () => {
  return (
    <>
      <BackButton label="Return to Users List" to="/users" />
      <Edit title={<UsersEditTitle />} undoable={false}>
        <SimpleForm toolbar={<CustomToolbar />}>
          <TextInput source="username" validate={[required()]} />
          <Labeled label="CAC Login Enabled">
            <TextField sx={{ pb: 1 }} source="hasEdipiHashYesNo" />
          </Labeled>

          {/* <SelectInput
            label="Role"
            source="role.roleId"
            choices={ROLES}
            optionValue="roleId"
            validate={[required()]}
          /> */}

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <SelectInput
                  label="Role"
                  source="role.roleId"
                  choices={ROLES}
                  optionValue="roleId"
                  validate={[required()]}
                  // disabled={isRoleStudent(formData.role.name)}
                />
              )}
            </FormDataConsumer>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.role.roleId === 2 && (
                  <SelectInput
                    label="Track"
                    source="track"
                    choices={INSTRUCTOR_TRACKS}
                    optionValue="name"
                    validate={[required()]}
                    // disabled={isRoleStudent(formData.role.name)}
                  />
                )
              }
            </FormDataConsumer>
          </div>

          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <ReferenceInput
                source="organization.id"
                reference="organizations"
                sort={{ field: 'name', order: 'ASC' }}
              >
                <SelectInput
                  label="Organization"
                  optionText="name"
                  disabled={isRoleStudent(formData.role.name)}
                />
              </ReferenceInput>
            )}
          </FormDataConsumer>

          <Labeled label="AppUser">
            <TextField sx={{ pb: 1 }} source="appUser.appUserAlias" />
          </Labeled>

          <ReferenceInput source="regStatus.lookupCodeId" reference="registrationstatuses">
            <SelectInput label="Registration Status" optionText="name" validate={required()} />
          </ReferenceInput>

          <ReferenceInput source="userStatus.lookupCodeId" reference="userstatuses">
            <SelectInput label="User Status" optionText="name" validate={required()} />
          </ReferenceInput>

          <TextInput source="email" validate={[required(), email()]} />
          <TextInput source="phoneNumber" validate={[required(), number()]} />

          <Labeled label="Approved By">
            <TextField sx={{ pb: 1 }} source="approvedBy" />
          </Labeled>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default UsersEdit;
