const logAndPass = (...params) => {
  console.log(...params);
  return params;
};
const logValueAndPass = (value, ...params) => {
  console.log(value, ...params);
  return params;
};

const logException =
  (fn, name = fn.name) =>
  (...params) => {
    try {
      return fn(...params);
    } catch (e) {
      console.log(`Exception in ${name}`, e);
      throw e;
    }
  };

const withLogging = (fn, name = fn.name) =>
  logException(
    (...params) =>
      logValueAndPass(`Result of ${name}:`, fn(...logValueAndPass(`Calling ${name} with arguments:`, ...params)))[0],
    name
  );

const LogProps = (props) => {
  console.log(props);
  return null;
};

/**
 * Usage:
 * `let debugFn = withLogging(fn, name)`
 *   - when debugFn called, the parameters passed in are logged, the function is called, the result is logged, and the result returned.
 *   - if the function throws an exception, that exception is logged and rethrown.
 *   - name is an optional parameter that, if specified, is used as the name of the function in the console log lines. If not specified, the name property of the passed function is used.
 *
 * `logAndPass(...values)`
 *   - returns values, but calls `console.log(...values)` before returning. Since the value is returned, can be inserted anywhere a statement is needed,
 *       or even as wrapper around arguments to a function (as in: `fn(...logAndPass(a, b, c)))`).
 *
 * `logValueAndPass(text, ...value)`
 *   - same as logAndPass, except that the first parameter to logValueAndPass is only logged and not returned in the resulting arguments.
 *
 * `<LogProps logprops_location="Bulk Action" ...props />`
 *   - LogProps behaves as a react function component that logs what props are passed in and returns null. This can be useful for debugging when you want to determine what props get injected into a component.
 *   - we recommend adding a prop named "logprops_location" so that you can easily identify your target log statements, but this is not necessary for the tool to function.
 *
 * `let debugFn = logException(fn, name)`
 *   - when debugFn is called, it will first call fn with the passed parameters, and if fn throws an exception, debugFn will log that exception to the console before rethrowing the exception,
 *      enabling you to trace an exception to its source.
 */
export { logAndPass, logValueAndPass, withLogging, LogProps, logException };

/*
//TEST CODE - TODO: make into unit test
import { logAndPass, logValueAndPass, withLogging } from './System/LoggingUtils'

console.log('Result of logAndPass:', ...logAndPass('asdf','asdfasdf','asdfasdfasdf'))
console.log('Result of logValueAndPass', ...logValueAndPass('Value', 'asdf','asdfasdf','asdfasdfasdf'))
const reverse = (...items) => items.sort()
withLogging(reverse, 'REVERSE')('z','y','x','c','b','a')
*/
