import React from 'react';
import TveButton from './TveButton';
import { PAUSE, PauseIcon } from '../Config/TveActions';

const PauseTveButton = (props) => (
  <TveButton
    label="Pause TVE"
    color="primary"
    icon={<PauseIcon />}
    action={PAUSE}
    confirm={true}
    fromTveStatuses={['Running']}
    fromTrainingStatuses="ALL"
    fromCanesVersions="ALL"
    {...props}
  />
);

export default PauseTveButton;
