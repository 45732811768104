import React from 'react';
import {
  InfiniteList,
  Datagrid,
  TextField,
  BulkDeleteButton,
  FunctionField,
  BooleanInput,
  TopToolbar,
} from 'react-admin';
import TveTitle from '../Components/TveTitle';
import ArchiveFeedbackButton from '../Components/ArchiveFeedbackButton';
import { Container, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Wrapper = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '365px auto',
  gridGap: '10px 2em',
});

const BulkActionButtons = () => (
  <>
    <BulkDeleteButton
      sx={{
        color: 'white',
        backgroundColor: 'red',
        padding: '6px 11px',
        '&:hover': {
          backgroundColor: '#0066CC',
        },
      }}
    />
    <ArchiveFeedbackButton />
  </>
);

const truncateText = (text, limit) => {
  if (text.length > limit) {
    return `${text.substring(0, limit)}...`;
  }
  return text;
};

const timestampToDate = (timestampstring) => {
  if (timestampstring === null) {
    return null;
  }
  return new Date(timestampstring).toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
};

const feedbackFilters = [
  <BooleanInput sx={{ pl: 3, pb: 1 }} label="Show Archived" source="hideArchived" alwaysOn />,
];

const FeedbackListActions = () => <TopToolbar />;

const Empty = () => (
  <Container>
    <Wrapper>
      <Box textAlign="left" m={1}>
        <Typography variant="h6">No feedback found</Typography>
      </Box>
    </Wrapper>
  </Container>
);

const FeedbackList = () => (
  <InfiniteList
    title={<TveTitle pageName="Feedback" />}
    filters={feedbackFilters}
    exporter={false}
    actions={<FeedbackListActions />}
    empty={<Empty />}
  >
    <Datagrid
      sx={{
        '& .column-feedbackId': { maxWidth: '35px' },
        '& .column-feedbackContent': { minWidth: '450px' },
        '& .column-DateReceived': { maxWidth: '20px' },
        '& .column-submittedBy': { minWidth: '50px' },
      }}
      bulkActionButtons={<BulkActionButtons />}
      rowClick="show"
    >
      <TextField source="feedbackId" label="id" />
      <FunctionField
        label="Date Received"
        render={(record) => timestampToDate(record.dateReceived)}
        sortBy="dateReceived"
      />
      <FunctionField
        source="feedbackContent"
        render={(record) => truncateText(record.feedbackContent, 140)}
      />
      <TextField source="submittedBy" />
      <TextField label="Archived" source="archived" />
      <FunctionField
        label="Date Archived"
        render={(record) => timestampToDate(record.dateArchived)}
        sortBy="dateArchived"
      />
    </Datagrid>
  </InfiniteList>
);

export default FeedbackList;
