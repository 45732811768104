import React from 'react';
import TveButton from './TveButton';
import { FINAL, FinalIcon } from '../Config/TveActions';

const TriggerFinalPracticumButton = (props) => (
  <TveButton
    label="Start Performance Test"
    color="primary"
    icon={<FinalIcon />}
    action={FINAL}
    confirm={true}
    fromTveStatuses={['Running']}
    fromTrainingStatuses="ALL"
    // fromCanesVersions={['SW3.0-n', 'SW3.0-R', 'SW3.0-v', 'SW4.0-A', 'SW4.0-M', 'SW4.0-v']}
    fromCanesVersions="Y"
    {...props}
  />
);

export default TriggerFinalPracticumButton;
