import * as React from 'react';
import { Admin, defaultTheme, Resource, CustomRoutes } from 'react-admin';
import { Route } from 'react-router-dom';
import RedirectHome from './Components/RedirectHome';
import LoginPage from './Pages/LoginPage';
import authProvider from './Config/AuthProvider';
import ApplicationDataProvider from './Config/ApplicationDataProvider';
import { TveLayout } from './Config/TveLayout';
import AppDashboard from './Pages/AppDashboard';
import TveInactiveStudentList from './Pages/TveInactiveStudentList';
import TveClassList from './Pages/TveClassList';
import TveClassListReporting from './Pages/TveClassListReporting';
import TveClassCreate from './Pages/TveClassCreate';
import TveClassEdit from './Pages/TveClassEdit';
import TveCourseList from './Pages/TveCourseList';
import TveCourseCreate from './Pages/TveCourseCreate';
import TveCourseEdit from './Pages/TveCourseEdit';
import TveTrainingLocationList from './Pages/TveTrainingLocationList';
import TveTrainingLocationCreate from './Pages/TveTrainingLocationCreate';
import TveTrainingLocationEdit from './Pages/TveTrainingLocationEdit';
import TveClassStatusList from './Pages/TveClassStatusList';
import TveClassStatusCreate from './Pages/TveClassStatusCreate';
import TveClassStatusEdit from './Pages/TveClassStatusEdit';
import TveStatusList from './Pages/TveStatusList';
import TveStatusCreate from './Pages/TveStatusCreate';
import TveStatusEdit from './Pages/TveStatusEdit';
import TveTrainingStatusList from './Pages/TveTrainingStatusList';
import TveTrainingStatusCreate from './Pages/TveTrainingStatusCreate';
import TveTrainingStatusEdit from './Pages/TveTrainingStatusEdit';
import TveCanesVersionList from './Pages/TveCanesVersionList';
import TveCanesVersionCreate from './Pages/TveCanesVersionCreate';
import TveCanesVersionEdit from './Pages/TveCanesVersionEdit';
import Administration from './Pages/Administration';
import AdministrationReporting from './Pages/AdministrationReporting';
import UsersList from './Pages/UsersList';
import UsersEdit from './Pages/UsersEdit';
import TveAppPropertyValueList from './Pages/TveAppPropertyValueList';
import TveAppPropertyValueCreate from './Pages/TveAppPropertyValueCreate';
import TveAppPropertyValueEdit from './Pages/TveAppPropertyValueEdit';
import TveClassAppUserCreate from './Pages/TveClassAppUserCreate';
import TveClassAppUserEdit from './Pages/TveClassAppUserEdit';
import FeedbackList from './Pages/FeedbackList';
import FeedbackCreate from './Pages/FeedbackCreate';
import FeedbackShow from './Pages/FeedbackShow';
import TveClassManage from './Pages/TveClassManage';
import Footer from './Pages/Footer';
import FAQAccordion from './Pages/FAQAccordion';
import TermAccordion from './Pages/TermAccordion';
import Resources from './Pages/Resources';
import InactiveStudentIcon from '@mui/icons-material/HowToReg';
import ClassIcon from '@mui/icons-material/SupervisedUserCircle';
import BookIcon from '@mui/icons-material/Book';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import AdministrationIcon from '@mui/icons-material/Layers';
import UsersIcon from '@mui/icons-material/AssignmentInd';
import ConfigIcon from '@mui/icons-material/Settings';
import EmailIcon from '@mui/icons-material/Email';
import AdministrationAndReports from './Pages/AdministrationAndReports';

const darkTheme = {
  ...defaultTheme,
  palette: { mode: 'dark' },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(
      ','
    ),
  },
  sidebar: {
    width: 220, // default is 240
    closedWidth: 55, // default is 55
  },
};

const lightTheme = {
  ...defaultTheme,
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(
      ','
    ),
    regPageText: {
      fontSize: 12,
      fontFamily: 'Roboto',
      color: 'black',
      display: 'block',
    },
    dashboardCardLabel: {
      fontFamily: 'Verdana',
      fontWeight: 'bold',
      fontSize: '12px',
      minWidth: '150px',
      display: 'inline-block',
    },
    dashboardCardText: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      minWidth: '100px',
      display: 'inline-block',
    },
  },
  sidebar: {
    width: 220, // default is 240
    closedWidth: 55, // default is 55
  },
};

const App = () => (
  <Admin
    disableTelemetry
    theme={lightTheme}
    darkTheme={darkTheme}
    title="Navy TVE"
    layout={TveLayout}
    loginPage={LoginPage}
    dashboard={AppDashboard}
    authProvider={authProvider}
    dataProvider={ApplicationDataProvider}
    catchAll={RedirectHome}
  >
    <CustomRoutes>
      <Route exact path="/tveclasses/:classId/students/*" element={<TveClassManage />} />,
    </CustomRoutes>
    {/* <CustomRoutes>
      <Route exact path="/tveclasses/:classId/students/*" element={<TveClassManage />} />,
    </CustomRoutes> */}
    <CustomRoutes>
      <Route
        exact
        path="/tveclasses/:classId/students/create"
        element={<TveClassAppUserCreate />}
      />
      ,
    </CustomRoutes>
    <CustomRoutes>
      <Route
        exact
        path="/tveclasses/:classId/students/:studentId/*"
        element={<TveClassAppUserEdit />}
      />
      ,
    </CustomRoutes>

    <CustomRoutes>
      <Route path="/help" element={<Footer />} />
    </CustomRoutes>
    <CustomRoutes>
      <Route path="/faq/*" element={<FAQAccordion />} />
    </CustomRoutes>
    <CustomRoutes>
      <Route path="/term/*" element={<TermAccordion />} />
    </CustomRoutes>
    <CustomRoutes>
      <Route path="/resources" element={<Resources />} />
    </CustomRoutes>

    <CustomRoutes>
      <Route path="/administrationandreports" element={<AdministrationAndReports />} />
    </CustomRoutes>

    <CustomRoutes>
      <Route path="/administrationreporting" element={<AdministrationReporting />} />
    </CustomRoutes>

    <CustomRoutes>
      <Route path="/tveclasslistreporting" element={<TveClassListReporting />} />
    </CustomRoutes>

    {(permissions) => (
      <>
        {permissions === 'INSTRUCTOR' || permissions === 'DEVELOPER' || permissions === 'ADMIN' ? (
          <>
            <Resource
              name="inactivestudents"
              options={{ label: 'Registration Activation' }}
              list={TveInactiveStudentList}
              icon={InactiveStudentIcon}
            />
            <Resource
              name="tveclasses"
              options={{ label: 'Classes' }}
              list={TveClassList}
              create={TveClassCreate}
              edit={TveClassEdit}
              icon={ClassIcon}
            />
          </>
        ) : null}

        {permissions === 'DEVELOPER' || permissions === 'ADMIN' ? (
          <>
            <Resource
              name="courses"
              options={{ label: 'Courses' }}
              list={TveCourseList}
              create={TveCourseCreate}
              edit={TveCourseEdit}
              icon={BookIcon}
            />
            <Resource
              name="traininglocations"
              options={{ label: 'Training Locations' }}
              list={TveTrainingLocationList}
              create={TveTrainingLocationCreate}
              edit={TveTrainingLocationEdit}
              icon={EditAttributesIcon}
            />
            <Resource
              name="classstatuses"
              options={{ label: 'Class Status' }}
              list={TveClassStatusList}
              create={TveClassStatusCreate}
              edit={TveClassStatusEdit}
              icon={EditAttributesIcon}
            />
            <Resource
              name="tvestatuses"
              options={{ label: 'TVE Status' }}
              list={TveStatusList}
              create={TveStatusCreate}
              edit={TveStatusEdit}
              icon={EditAttributesIcon}
            />
            <Resource
              name="usertrainingstatuses"
              options={{ label: 'User Training Status' }}
              list={TveTrainingStatusList}
              create={TveTrainingStatusCreate}
              edit={TveTrainingStatusEdit}
              icon={EditAttributesIcon}
            />
            <Resource
              name="canesversions"
              options={{ label: 'Stack Versions' }}
              list={TveCanesVersionList}
              create={TveCanesVersionCreate}
              edit={TveCanesVersionEdit}
              icon={EditAttributesIcon}
            />
          </>
        ) : null}

        {permissions === 'DEVELOPER' || permissions === 'ADMIN' ? (
          <>
            <Resource
              name="administration"
              options={{ label: 'Administration' }}
              list={Administration}
              icon={AdministrationIcon}
            />
          </>
        ) : null}

        {permissions === 'ADMIN' ? (
          <>
            <Resource
              name="users"
              options={{ label: 'Users' }}
              list={UsersList}
              edit={UsersEdit}
              icon={UsersIcon}
            />
          </>
        ) : null}

        {permissions === 'DEVELOPER' || permissions === 'ADMIN' ? (
          <>
            <Resource
              name="tveapppropvalues"
              options={{ label: 'Settings' }}
              list={TveAppPropertyValueList}
              create={TveAppPropertyValueCreate}
              edit={TveAppPropertyValueEdit}
              icon={ConfigIcon}
            />
            <Resource
              name="feedback"
              options={{ label: 'Feedback' }}
              list={FeedbackList}
              create={FeedbackCreate}
              show={FeedbackShow}
              icon={EmailIcon}
            />
          </>
        ) : null}
      </>
    )}
  </Admin>
);

export default App;
