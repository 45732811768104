import { 
  createProviderConfig,
  configuredProvider,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
  UPDATE_MANY,
  CREATE,
  DELETE,
  DELETE_MANY
} from './configuredProvider';

const configuredProviders = (config) => {
  let providerConfigs = {};

  for (var resource in config.resources) {
    providerConfigs[resource] = createProviderConfig(resource, config.resources[resource], config.defaults);
  }

  return (type, resource, params = {}) => {
    if (resource in providerConfigs) return configuredProvider(type, params, providerConfigs[resource]);

    return configuredProvider(type, params, createProviderConfig(resource, {}, config.defaults));
  };
};

const ra4ConfiguredProviders = (config) => {

  const ra2Providers = configuredProviders(config)

  return {
    getList: (resource, params) => ra2Providers(GET_LIST, resource, params),
    getOne: (resource, params) => ra2Providers(GET_ONE, resource, params),
    getMany: (resource, params) => ra2Providers(GET_MANY, resource, params),
    getManyReference: (resource, params) => ra2Providers(GET_MANY_REFERENCE, resource, params),
    create: (resource, params) => ra2Providers(CREATE, resource, params),
    update: (resource, params) => ra2Providers(UPDATE, resource, params),
    updateMany: (resource, params) => ra2Providers(UPDATE_MANY, resource, params),
    delete: (resource, params) => ra2Providers(DELETE, resource, params),
    deleteMany: (resource, params) => ra2Providers(DELETE_MANY, resource, params)
  }
}

export default ra4ConfiguredProviders;
