import React from 'react';
import TveButton from './TveButton';
import { CREATE, CreateIcon } from '../Config/TveActions';

const CreateTveButton = (props) => (
  <TveButton
    label="Create TVE"
    color="primary"
    icon={<CreateIcon />}
    action={CREATE}
    confirm={true}
    fromTveStatuses={['Not Created', 'Deleted']}
    fromTrainingStatuses={['Not Started', 'In Progress']}
    fromCanesVersions="ALL"
    {...props}
  />
);

export default CreateTveButton;
