import React, { useState } from 'react';
import {
  Button,
  Confirm,
  usePermissions,
  useListContext,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from 'react-admin';

const SchedulerButton = ({ label, color, icon, action }) => {
  const { data, selectedIds } = useListContext();
  const [open, setOpen] = useState(false);
  const { permissions } = usePermissions();

  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll('tveclasses');

  const handleClick = () => {
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);
  const [updateMany, { isLoading }] = useUpdateMany();
  const handleConfirm = () => {
    setOpen(false);
    updateMany(
      'tveclasses',
      { ids: selectedIds, data: { schedule: { action } } },
      {
        onSuccess: () => {
          refresh();
          unselectAll();
        },
        onError: (error) => notify('scheduler button error occured', { type: 'warning' }),
      }
    );
  };

  const affectedClasses = data
    .filter((it) => selectedIds.includes(it.id))
    .filter((tveClass) => {
      return true;
    });

  switch (permissions) {
    case 'INSTRUCTOR':
    case 'ADMIN': {
      return (
        <>
          <Button
            onClick={handleClick}
            label={`${label} (${affectedClasses.length})`}
            variant="contained"
            color={color}
          >
            {icon}
          </Button>
          <Confirm
            isOpen={open}
            loading={isLoading}
            title={`${label} for ${affectedClasses.length} classes`}
            content={`Are you sure you want to ${label} for ${affectedClasses
              .map((tveclass) => tveclass.name)
              .join(', ')}?`}
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
          />
        </>
      );
    }
    default:
      return null;
  }
};

export default SchedulerButton;
