import React from 'react';
import SchedulerButton from './SchedulerButton';
import { UNSCHEDULE_PAUSE } from '../Config/ScheduleActions';
import { UnscheduleIcon } from '../Config/ScheduleActions';

const UnschedulePauseTveButton = (props) => (
  <SchedulerButton
    label="Disable Auto-Pause"
    color="primary"
    icon={<UnscheduleIcon />}
    action={UNSCHEDULE_PAUSE}
    {...props}
  />
);

export default UnschedulePauseTveButton;
