export const Data = [
  {
    question: 'Amazon Web Services (AWS)',
    answer: 'An infrastructure web services platform in the cloud for companies of all sizes. ',
  },

  {
    question: 'Class Roster',
    answer:
      'A list of important records for the class. In the TVE, the class roster displays the list of TVE stacks that have been created for the class as well as to whom they were assigned and what state they are in (Not created, Create requested, Running, Paused, Delete Requested, Deleted).',
  },

  {
    question: 'Dashboard',
    answer:
      'Is a user interface, which can bear some resemblance to a car’s control panel, where information is organized and presented in a way that is easy to read. In the TVE Web UI, the Student Dashboard contains links to TVE Resources and TVE Training Stacks; whereas the Instructor Dashboard displays the Classes page.',
  },

  {
    question: 'ESXi',
    answer:
      'A Type 1 hypervisor, also referred to as a bare-metal hypervisor, meaning it runs directly on system hardware without the need for an Operating System (OS). Hypervisors help run multiple Virtual Machines (VMs) efficiently on a physical server.',
  },

  {
    question: 'Nested Virtualization',
    answer:
      'Is a complex process that involves running virtual machines within virtual machines. This process is made possible through the use of hypervisors, which are specialized software programs that manage the operating systems needed within virtual environments. Hypervisors are responsible for allocating essential resources like processing power, memory, and other resources that your virtual environments require to function. ',
  },

  {
    question: 'Record',
    answer:
      'A record in a database is a collection of information organized in a table that pertains to a specific topic or category. The TVE Web UI uses records for students or instructors and classes.',
  },

  {
    question: 'Stack Seasoning',
    answer:
      'The initial process required to fully develop the TVE Training Stack to its optimal operating state.',
  },

  {
    question: 'Training Virtual Environment (TVE)',
    answer:
      'Training Virtual Environment (TVE): A cloud-hosted training environment. The TVE enables students’ individualized access to their own specific network training environment to learn the administration, management, and troubleshooting of various Command, Control, Communications, Computers and Intelligence (C4I) Systems.',
  },

  {
    question: 'TVE Training Stack',
    answer:
      'The virtual training environment that is hosted on AWS GovCloud. The term stack is used in cloud computing because it typically encompasses many different types of services which have been built on top of each other under a “cloud.”',
  },

  {
    question: 'TVE Web UI',
    answer:
      'The website that is used to manage TVE accounts, classes, student TVE Training Stacks, and contains the links for logging in to the TVE Training Stacks.',
  },

  {
    question: 'Windows 10 Landing Desktop',
    answer:
      'The webpage on which a TVE user arrives after clicking on their ENTER MY TVE TRAINING STACK hyperlink. It emulates a workstation on a ship or the utility laptop for accessing all other Virtual Machines (VMs).',
  },
];
