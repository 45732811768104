import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  FunctionField,
  RichTextField,
  Title,
  DateField,
} from 'react-admin';
import BackButton from '../Components/BackButton';
import TveTitle from '../Components/TveTitle';

const timestampToDateTime = (timestampstring) => {
  if (timestampstring === null) {
    return null;
  }
  const formattedDateTime = new Date(timestampstring).toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'UTC',
  });

  return `${formattedDateTime} UTC`;
};

export const FeedbackShow = () => (
  <>
    <Title title={<TveTitle pageName="Feedback Item" />} />
    <BackButton label="Return to Feedback" to="/feedback" />
    <Show>
      <SimpleShowLayout>
        <TextField source="feedbackId" label="Feedback Id" />
        <FunctionField
          label="Date Received"
          render={(record) => timestampToDateTime(record.dateReceived)}
          sortBy="Date Received"
        />
        <TextField source="submittedBy" label="Submitted By" />
        <RichTextField source="feedbackContent" label="Feedback Content" />
        <FunctionField
          label="Date Archived"
          render={(record) => timestampToDateTime(record.dateArchived)}
          sortBy="Date Archived"
        />
        <TextField source="archivedBy" label="Archived By" />
      </SimpleShowLayout>
    </Show>
  </>
);

export default FeedbackShow;
