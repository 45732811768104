import React from 'react';
import { useRecordContext } from 'react-admin';
import { Typography, CircularProgress, LinearProgress } from '@mui/material';

const TveStatus = () => {
  const record = useRecordContext();

  if (record.tveStatus.name.endsWith('...'))
    return (
      <div style={{ position: 'relative', fontSize: '7px' }}>
        <span
          style={{
            position: 'absolute',
            top: '14px',
            left: '4px',
            fontSize: '7px',
            fontFamily: 'Roboto',
          }}
        >
          {record.tveStatus.name}
        </span>
        <CircularProgress />
      </div>
    );

if (record.tveStatus.name === 'Create Startup')
  return (
    <>
      {record.tveStatus.name}
      <LinearProgress />
    </>
  );

  return <Typography variant="h7">{record.tveStatus.name}</Typography>;
};

export default TveStatus;
