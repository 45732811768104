import React from 'react';
import TveButton from './TveButton';
import { EXTEND, ExtendIcon } from '../Config/TveActions';

const ExtendScheduledTimeButton = (props) => (
  <TveButton
    label="Extend Pause Time"
    color="primary"
    icon={<ExtendIcon />}
    action={EXTEND}
    confirm={false}
    fromTveStatuses={['Running']}
    fromTrainingStatuses={['Not Started', 'In Progress']}
    fromCanesVersions="ALL"
    {...props}
  />
);

export default ExtendScheduledTimeButton;
