import React from 'react';
import TveButton from './TveButton';
import { START, StartIcon } from '../Config/TveActions';

const StartTveButton = (props) => (
  <TveButton
    label="Start TVE"
    color="primary"
    icon={<StartIcon />}
    action={START}
    confirm={true}
    fromTveStatuses={['Created', 'Paused']}
    fromTrainingStatuses="ALL"
    fromCanesVersions="ALL"
    {...props}
  />
);

export default StartTveButton;
