import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  maxLength,
  choices,
} from 'react-admin';
import BackButton from '../Components/BackButton';
import TveTitle from '../Components/TveTitle';

const validateYesOrNo = choices(['Y', 'N'], 'Please choose one of the values');

const TveCanesVersionCreate = () => {
  return (
    <>
      <BackButton label="Return to Stack Version List" to="/canesversions" />
      <Create title={<TveTitle pageName="Create Stack Version" />} redirect="list">
        <SimpleForm>
          <TextInput source="name" label="Name" fullWidth validate={[required(), maxLength(255)]} />
          <TextInput
            label="Description"
            source="description"
            fullWidth
            defaultValue=""
            validate={[required(), maxLength(255)]}
          />
          <TextInput
            label="Hardware Version"
            source="hardwareVersion"
            fullWidth
            defaultValue=""
            validate={[required(), maxLength(255)]}
          />
          <TextInput
            label="Software Version"
            source="softwareVersion"
            fullWidth
            defaultValue=""
            validate={[required(), maxLength(255)]}
          />
          <SelectInput
            label="Has Performance Test"
            source="hasPerformanceTest"
            choices={[
              { id: 'Y', name: 'Y' },
              { id: 'N', name: 'N' },
            ]}
            validate={[required(), validateYesOrNo()]}
            sx={{ width: 200 }}
          />
          <SelectInput
            label="Active"
            source="active"
            choices={[
              { id: 'Y', name: 'Y' },
              { id: 'N', name: 'N' },
            ]}
            validate={[required(), validateYesOrNo()]}
            sx={{ width: 200 }}
          />
        </SimpleForm>
      </Create>
    </>
  );
};

export default TveCanesVersionCreate;
