class StoredUser {
  constructor(storageKey = 'user', storageProvider = localStorage) {
    this.storageKey = storageKey;
    this.storageProvider = storageProvider;
  }

  encode(userData) {
    return JSON.stringify(userData);
  }

  decode(userDataString) {
    return JSON.parse(userDataString);
  }

  store(userData) {
    this.storageProvider.setItem(this.storageKey, this.encode(userData));
  }

  load() {
    return this.decode(this.storageProvider.getItem(this.storageKey));
  }

  remove() {
    this.storageProvider.removeItem(this.storageKey);
  }

  exists() {
    return this.storageProvider.getItem(this.storageKey) !== null;
  }

  getPermissions() {
    return this.exists() ? this.load().permissions : null;
  }
}

export default StoredUser;
