import React from 'react';
import { Create, SimpleForm, TextInput, required, maxLength } from 'react-admin';
import BackButton from '../Components/BackButton';
import TveTitle from '../Components/TveTitle';

const TveClassStatusCreate = () => {
  return (
    <>
      <BackButton label="Return to Class Status List" to="/classstatuses" />
      <Create title={<TveTitle pageName="Create Class Status" />} redirect="list">
        <SimpleForm>
          <TextInput
            source="name"
            label="Class Status"
            fullWidth
            validate={[required(), maxLength(255)]}
          />
          <TextInput
            source="description"
            label="Description"
            fullWidth
            defaultValue=""
            validate={maxLength(255)}
          />
          <TextInput
            label={false}
            type="hidden"
            source="lookupType.lookupTypeId"
            defaultValue={1}
            InputProps={{ disableUnderline: true }}
          />
          <TextInput
            label={false}
            type="hidden"
            source="lookupType.name"
            defaultValue="Class Status"
            InputProps={{ disableUnderline: true }}
          />
          <TextInput
            label={false}
            type="hidden"
            source="lookupType.description"
            defaultValue=""
            InputProps={{ disableUnderline: true }}
          />
        </SimpleForm>
      </Create>
    </>
  );
};
export default TveClassStatusCreate;
