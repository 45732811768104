import React from 'react';
import { InfiniteList, Datagrid, TextField, DateField } from 'react-admin';
import TveTitle from '../Components/TveTitle';
import ActivateStudentButton from '../Components/ActivateStudentButton';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const BulkActionButtons = () => <ActivateStudentButton />;

const Wrapper = styled(Box)({
  display: 'grid',
  paddingTop: '20px',
});

const Empty = () => (
  <Wrapper>
    <Box textAlign="left" m={1}>
      <Typography variant="h6">No inactive students found</Typography>
    </Box>
  </Wrapper>
);

const TveInactiveStudentList = () => (
  <InfiniteList
    title={<TveTitle pageName="Instructors / Students to be Activated" />}
    exporter={false}
    sort={{ field: 'creationTimestamp', order: 'DESC' }}
    perPage={25}
    empty={<Empty />}
  >
    <Datagrid bulkActionButtons={<BulkActionButtons />}>
      <TextField source="username" />
      <TextField source="role.name" label="Role" />
      <TextField source="organization.name" label="Organization" />
      <TextField source="email" label="Email" />
      <TextField source="phoneNumber" label="Phone Number" />
      <DateField source="creationTimestamp" label="Submitted on" showTime />
    </Datagrid>
  </InfiniteList>
);

export default TveInactiveStudentList;
