import React from 'react';
import {
  InfiniteList,
  TextField,
  CreateButton,
  TopToolbar,
  DatagridConfigurable,
  SelectColumnsButton,
  BulkDeleteButton,
} from 'react-admin';
import TveTitle from '../Components/TveTitle';

const BulkActionButtons = () => (
  <>
    <BulkDeleteButton
      sx={{
        color: 'white',
        backgroundColor: 'red',
        padding: '6px 11px',
        '&:hover': {
          backgroundColor: '#0066CC',
        },
      }}
    />
  </>
);

const TveCourseCreateButton = () => <CreateButton label="add course" variant="contained" />;

const TveCourseListActions = () => (
  <TopToolbar>
    <TveCourseCreateButton variant="contained" />
    <SelectColumnsButton variant="contained" />
  </TopToolbar>
);

const TveCourseList = () => (
  <InfiniteList title={<TveTitle pageName="Courses" />} actions={<TveCourseListActions />}>
    <DatagridConfigurable
      bulkActionButtons={<BulkActionButtons />}
      rowClick="edit"
      omit={['totalClassesOfThisType', 'courseId']}
    >
      <TextField label="Course Name" source="name" />
      <TextField source="description" />
      <TextField label="# Classes Using" source="totalClassesOfThisType" />
      <TextField label="Course Id" source="courseId" />
    </DatagridConfigurable>
  </InfiniteList>
);
export default TveCourseList;
