import React from 'react';
import ArchiveIcon from '@mui/icons-material/CheckCircleOutline';
import {
  Button,
  useListContext,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from 'react-admin';

const ArchiveFeedbackButton = () => {
  const { selectedIds } = useListContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll('feedback');
  const handleClick = () => {
    updateMany();
  };

  const [updateMany, { isLoading }] = useUpdateMany(
    'feedback',
    { ids: selectedIds, data: { views: 0 } },
    {
      onSuccess: () => {
        refresh();
        notify('feedback archived');
        unselectAll();
      },
      onError: (error) => notify('feedback not archived: ' + error, { type: 'warning' }),
    }
  );

  return (
    <>
      <Button
        label="Archive"
        disabled={isLoading}
        onClick={handleClick}
        variant="contained"
        sx={{
          pb: 0.8,
          pt: 0.8,
          justifyContent: 'center',
          width: '85px',
        }}
      >
        <ArchiveIcon />
      </Button>
    </>
  );
};

export default ArchiveFeedbackButton;
