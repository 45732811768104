import {
    SCHEDULE_PAUSE,
    UNSCHEDULE_PAUSE,
    SCHEDULE_STARTUP,
    UNSCHEDULE_STARTUP
} from '../Config/ScheduleActions'

const actionEndpoints = {
    [SCHEDULE_PAUSE]:    'schedulepause',
    [UNSCHEDULE_PAUSE]:  'unschedulepause',
    [SCHEDULE_STARTUP]:  'schedulestartup',
    [UNSCHEDULE_STARTUP]: 'unschedulestartup'
}

const updateSchedule = (tveClassId, schedule, config) => {
    const { fetchClient, apiUrl } = config
    if (!(schedule.action in actionEndpoints))
        return Promise.resolve()

    return fetchClient({
        method: 'get',
        url: `${apiUrl}/tveclasses/${actionEndpoints[schedule.action]}/${tveClassId}`
    }).then(
        response => response.data
    )
}

export default updateSchedule
