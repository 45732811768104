import React from 'react';
import SchedulerButton from './SchedulerButton';
import { SCHEDULE_PAUSE } from '../Config/ScheduleActions';
import { ScheduleIcon } from '../Config/ScheduleActions';

const SchedulePauseTveButton = (props) => (
  <SchedulerButton
    label="Enable Auto-Pause"
    color="primary"
    icon={<ScheduleIcon />}
    action={SCHEDULE_PAUSE}
    {...props}
  />
);

export default SchedulePauseTveButton;
