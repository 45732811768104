import React from 'react';
import ActivateIcon from '@mui/icons-material/CheckCircleOutline';
import {
  Button,
  useListContext,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from 'react-admin';

const ActivateStudentButton = () => {
  const { selectedIds } = useListContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll('inactivestudents');
  const handleClick = () => {
    updateMany();
  };

  const [updateMany, { isLoading }] = useUpdateMany(
    'inactivestudents',
    { ids: selectedIds, data: { views: 0 } },
    {
      onSuccess: () => {
        refresh();
        notify('users activated');
        unselectAll();
      },
      onError: (error) => notify('users not updated: ' + error, { type: 'warning' }),
    }
  );

  return (
    <>
      <Button label="Activate" disabled={isLoading} onClick={handleClick} variant="contained">
        <ActivateIcon />
      </Button>
    </>
  );
};

export default ActivateStudentButton;
