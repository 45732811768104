import React from 'react';
import TveButton from './TveButton';
import { DROP, DropIcon } from '../Config/TveActions';

const DropStudentButton = (props) => (
  <TveButton
    label="Drop Student"
    color="primary"
    icon={<DropIcon />}
    action={DROP}
    confirm={true}
    fromTveStatuses={['Not Created', 'Created', 'Running', 'Paused', 'Deleted']}
    fromTrainingStatuses={['Not Started', 'In Progress']}
    fromCanesVersions="ALL"
    {...props}
  />
);

export default DropStudentButton;
