import axios from 'axios';
import _ from 'lodash';

//consts for request types for backwards compatibility - these used to be in React Admin in v2.
const GET_LIST = "GET_LIST"
const GET_MANY = "GET_MANY"
const GET_MANY_REFERENCE = "GET_MANY_REFERENCE"
const GET_ONE = "GET_ONE"
const UPDATE = "UPDATE"
const UPDATE_MANY = "UPDATE_MANY"
const CREATE = "CREATE"
const DELETE = "DELETE"
const DELETE_MANY = "DELETE_MANY"

const IDENTITY = (it) => it;

const ID_TRANSFORM = (idFieldName) => (item) => {
  item.id = item[idFieldName];
  return item;
};

const sort = (items, params) => {
  if (!_.get(params, 'sort.field') || !_.get(params, 'sort.order')) return items;

  const fieldGetter = (item) => {
    const value = _.get(item, params.sort.field);

    return typeof value === 'string' ? value.toLowerCase() : value;
  };

  const sorted = _.sortBy(items, [fieldGetter]);

  return params.sort.order === 'ASC' ? sorted : _.reverse(sorted);
};

const paginate = (items, params) => {
  if (!_.get(params, 'pagination.page') || !_.get(params, 'pagination.perPage')) return items;

  if (items.length < params.pagination.perPage) return items;

  let startIndex = (params.pagination.page - 1) * params.pagination.perPage;
  let endIndex = params.pagination.page * params.pagination.perPage;
  items = items.slice(startIndex, endIndex);

  return items;
};

const sortAndPaginate = (items, params) => paginate(sort(items, params), params);
const sortAndPaginateResponse = (response, params) => {
  response.data = sortAndPaginate(response.data, params);
  return response;
};
const withTotal = (response) => {
  response.total = response.data.length;
  return response;
};

const withId = (item, id) => {
  item.id = id;
  return item;
};

const multiplex = (ids, iterator) => {
  return Promise.all(ids.map(iterator)).then((responses) =>
    withTotal({
      data: responses.map((it) => it.data).filter((it) => it !== null),
    })
  );
};

const transformListResponse = (params, config) => (response) => {
  response.data = response.data.map(config.transform);
  return sortAndPaginateResponse(withTotal(response), params);
};

const transformSingleResponse = (params, config) => (response) => {
  if (response.data) response.data = config.transform(response.data);
  return response;
};

const defaultConfigs = {
  fetchClient: axios,
  queryParams: ({ filter }) => ({ ...filter }),
  transform: IDENTITY,
  untransform: IDENTITY,
  transformListResponse: transformListResponse,
  transformSingleResponse: transformSingleResponse,
  providerDecorator: IDENTITY,
};

const createProviderConfig = (resource, resourceConfig, applicationDefaults) => {
  let config = Object.assign({}, defaultConfigs, applicationDefaults, resourceConfig);

  config.resource = resource;
  if (!('endpoint' in config)) config.endpoint = resource;
  config.baseUrl = `${config.apiUrl}/${config.endpoint}`;

  return config;
};

const baseConfiguredProvider = (type, params, config) => {
  switch (type) {
    case GET_LIST:
      return config
        .fetchClient({
          method: 'get',
          url: config.baseUrl,
          params: config.queryParams(params, config),
        })
        .then(config.transformListResponse(params, config));

    case GET_MANY:
    case GET_MANY_REFERENCE:
      return multiplex(params.ids, (id) => configuredProvider(GET_ONE, withId(params, id), config));

    case GET_ONE:
      return config
        .fetchClient({
          method: 'get',
          url: `${config.baseUrl}/${params.id}`,
          params: config.queryParams(params, config),
        })
        .then(config.transformSingleResponse(params, config));

    case UPDATE:
      return config
        .fetchClient({
          method: 'put',
          url: `${config.baseUrl}/${params.id}`,
          data: config.untransform(params.data),
          params: config.queryParams(params, config),
        })
        .then(config.transformSingleResponse(params, config));

    case UPDATE_MANY:
      return multiplex(params.ids, (id) => configuredProvider(UPDATE, withId(params, id), config));

    case CREATE:
      return config
        .fetchClient({
          method: 'post',
          url: config.baseUrl,
          data: config.untransform(params.data),
          params: config.queryParams(params, config),
        })
        .then(config.transformSingleResponse(params, config));

    case DELETE:
      return config
        .fetchClient({
          method: 'delete',
          url: `${config.baseUrl}/${params.id}`,
          params: config.queryParams(params, config),
        })
        .then(config.transformSingleResponse(params, config));

    case DELETE_MANY:
      return multiplex(params.ids, (id) => configuredProvider(DELETE, withId(params, id), config));

    default:
      return Promise.resolve(null);
  }
};

const configuredProvider = (type, params, config) => {
  return config.providerDecorator(baseConfiguredProvider)(type, params, config);
};

const helpers = {
  sort,
  paginate,
  sortAndPaginate,
  sortAndPaginateResponse,
  withTotal,
  withId,
};

export {
  IDENTITY,
  ID_TRANSFORM,
  configuredProvider,
  createProviderConfig,
  helpers,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
  UPDATE_MANY,
  CREATE,
  DELETE,
  DELETE_MANY
};
