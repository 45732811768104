import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  maxLength,
  Toolbar,
  SaveButton,
  useGetList,
  useRecordContext,
  DeleteWithConfirmButton,
} from 'react-admin';
import BackButton from '../Components/BackButton';
import TveTitle from '../Components/TveTitle';
import { CircularProgress } from '@mui/material';

const CustomToolbar = () => {
  const record = useRecordContext();
  const { data, isLoading, error } = useGetList('tveclasses', {
    pagination: { page: 1, perPage: 1000 },
  });
  const deleteConfirmationMessageTitle = `Delete course: ` + record.name;
  if (isLoading) {
    return <CircularProgress />;
  }
  if (error) {
    return <p>{error.message}</p>;
  }
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton />
      {data !== undefined && (
        <DeleteWithConfirmButton
          disabled={data.filter((i) => i.course.courseId === record.courseId).length > 0}
          confirmTitle={deleteConfirmationMessageTitle}
          confirmContent="Are you sure you want to delete this course?"
          variant="contained"
          sx={{
            color: 'white',
            backgroundColor: 'red',
            padding: '6px 11px',
            '&:hover': {
              backgroundColor: '#0066CC',
            },
          }}
        />
      )}
    </Toolbar>
  );
};

const TveCourseEdit = () => {
  return (
    <>
      <BackButton label="Return to Course List" to="/courses" />
      <Edit title={<TveTitle pageName="Course Edit" />}>
        <SimpleForm toolbar={<CustomToolbar />}>
          <TextInput
            label="Course name"
            source="name"
            fullWidth
            validate={[required(), maxLength(255)]}
          />
          <TextInput source="description" fullWidth validate={maxLength(225)} />
        </SimpleForm>
      </Edit>
    </>
  );
};
export default TveCourseEdit;
