import React from 'react';
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  TextInput,
  SelectInput,
  Toolbar,
  SaveButton,
  DeleteButton,
} from 'react-admin';
import BackButton from '../Components/BackButton';

const CustomToolbar = () => (
  <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <SaveButton />
    <DeleteButton
      variant="contained"
      sx={{
        color: 'white',
        backgroundColor: 'red',
        padding: '6px 11px',
        '&:hover': {
          backgroundColor: '#0066CC',
        },
      }}
    />
  </Toolbar>
);

const TveAppPropertyValueEdit = (props) => {
  return (
    <>
      <BackButton label="Return to Configuration List" to="/tveapppropvalues" />
      <Edit>
        <SimpleForm toolbar={<CustomToolbar />}>
          <TextInput disabled label="Property" source="tveProp.tvePropKey" fullWidth />
          <TextInput label="Value" source="tvePropValue" fullWidth />

          {props.permissions === 'ADMIN' &&
            ((
              <SelectInput
                source="scope"
                optionText="optionText"
                optionValue="optionValue"
                choices={['User', 'System'].map((it) => ({
                  optionText: it,
                  optionValue: it,
                }))}
                fullWidth
              />
            ),
            (
              <ReferenceInput
                source="appUser.appUserId"
                reference="appusers"
                label="User"
                fullWidth
              >
                <SelectInput optionText="appUserAlias" />
              </ReferenceInput>
            ))}
        </SimpleForm>
      </Edit>
    </>
  );
};

export default TveAppPropertyValueEdit;
