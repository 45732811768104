import React from 'react';
import { InfiniteList, TextField, Datagrid, CreateButton, TopToolbar, BulkDeleteButton } from 'react-admin';
import TveTitle from '../Components/TveTitle';

const TveStatusListActions = () => (
  <TopToolbar>
    <CreateButton label="add tve status" variant="contained" />
  </TopToolbar>
);

const BulkActionButtons = () => (
  <>
    <BulkDeleteButton
      sx={{
        color: 'white',
        backgroundColor: 'red',
        padding: '6px 11px',
        '&:hover': {
          backgroundColor: '#0066CC',
        },
      }}
    />
  </>
);

const TveStatusList = () => (
  <InfiniteList
    queryOptions={{ meta: { lookup_type: 'tve status' } }}
    title={<TveTitle pageName="TVE Statuses" />}
    perPage={25}
    actions={<TveStatusListActions />}
  >
    <Datagrid rowClick="edit" bulkActionButtons={<BulkActionButtons />}>
      <TextField label="TVE Status" source="name" />
      <TextField source="description" />
    </Datagrid>
  </InfiniteList>
);
export default TveStatusList;
