import React from 'react';
import { Create, SimpleForm, TextInput, required, maxLength } from 'react-admin';
import BackButton from '../Components/BackButton';
import TveTitle from '../Components/TveTitle';

const TveCourseCreate = () => {
  return (
    <>
      <BackButton label="Return to Course List" to="/courses" />
      <Create title={<TveTitle pageName="Create Course" />} redirect="list">
        <SimpleForm>
          <TextInput
            source="name"
            label="Course Name"
            fullWidth
            validate={[required(), maxLength(255)]}
          />
          <TextInput
            source="description"
            label="Description"
            defaultValue=""
            fullWidth
            validate={maxLength(255)}
          />
        </SimpleForm>
      </Create>
    </>
  );
};

export default TveCourseCreate;
