import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  maxLength,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import BackButton from '../Components/BackButton';
import TveTitle from '../Components/TveTitle';

const TveTrainingLocationCreate = () => {
  return (
    <>
      <BackButton label="Return to User Training Location List" to="/traininglocations" />
      <Create title={<TveTitle pageName="Create Tve Training Location" />} redirect="list">
        <SimpleForm>
          <TextInput
            source="location"
            label="Tve Training Location"
            validate={[required(), maxLength(255)]}
            sx={{ width: 500 }}
          />
          <TextInput
            source="state"
            label="State"
            validate={[required(), maxLength(2)]}
            sx={{ width: 500 }}
          />
          <TextInput
            source="country"
            label="Country"
            validate={[required(), maxLength(255)]}
            sx={{ width: 500 }}
          />
          <TextInput
            source="displayName"
            label="Display Name"
            validate={[required(), maxLength(255)]}
            sx={{ width: 500 }}
          />
          <ReferenceInput
            source="tveTimeZone"
            reference="timezones"
            sort={{ field: 'sort', order: 'ASC' }}
          >
            <SelectInput label="Default Timezone" optionText="official" validate={required()} />
          </ReferenceInput>
          <TextInput
            source="sort"
            label="Sort Order"
            defaultValue="99"
            validate={[required(), maxLength(255)]}
          />
        </SimpleForm>
      </Create>
    </>
  );
};
export default TveTrainingLocationCreate;
