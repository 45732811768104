import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  useNotify,
  useRedirect,
} from 'react-admin';
import BackButton from '../Components/BackButton';
import TveTitle from '../Components/TveTitle';

const formDefaultValues = () => ({ feedbackContent: '\n\n' });

const validateFeedback = async (values) => {
  const errors = {};
  const invalidChars = ['/', '\\', '%', '?', '|', '[', ']', '#'];
  if (!values.feedbackContent.replace(/\n/g, '')) {
    errors.feedbackContent = 'No feedback entered';
  } else if (invalidChars.some((char) => values.feedbackContent.includes(char))) {
    errors.feedbackContent = 'Feedback contains an invalid character: /, \\, %, ?, |, [, ], or #';
  } else if (values.feedbackContent.replace(/\n/g, '')?.length > 1000) {
    errors.feedbackContent = 'Feedback must be not be > 1000 characters';
  }
  return errors;
};

const CustomToolbar = () => (
  <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <SaveButton label="Submit This Feedback" />
  </Toolbar>
);

const FeedbackCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (data) => {
    notify(`Feedback submitted`);
    redirect('/help');
  };

  return (
    <>
      <BackButton label="Return to Help and Support" to="/help" />
      <Create title={<TveTitle pageName="Create Feedback" />} mutationOptions={{ onSuccess }}>
        <SimpleForm
          defaultValues={formDefaultValues()}
          mode="onBlur"
          reValidateMode="onBlur"
          validate={validateFeedback}
          toolbar={<CustomToolbar />}
        >
          <TextInput
            source="feedbackContent"
            label="Enter feedback here"
            multiline
            fullWidth
            sx={{ width: 1000 }}
          />
        </SimpleForm>
      </Create>
    </>
  );
};
export default FeedbackCreate;
