import React from 'react';
import TveButton from './TveButton';
import { DELETE, DeleteIcon } from '../Config/TveActions';

const DeleteTveButton = (props) => (
  <TveButton
    label="Delete TVE"
    color="primary"
    icon={<DeleteIcon />}
    action={DELETE}
    confirm={true}
    fromTveStatuses={[
      'Create Failed',
      'Delete Failed',
      'Rollback Complete',
      'Rollback Failed',
      'Rollback-Not Created',
    ]}
    fromTrainingStatuses="ALL"
    fromCanesVersions="ALL"
    {...props}
  />
);

export default DeleteTveButton;
