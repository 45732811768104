import React from 'react';
import SchedulerButton from './SchedulerButton';
import { SCHEDULE_STARTUP } from '../Config/ScheduleActions';
import { ScheduleIcon } from '../Config/ScheduleActions';

const ScheduleStartupTveButton = (props) => (
  <SchedulerButton
    label="Enable Auto-Start"
    color="primary"
    icon={<ScheduleIcon />}
    action={SCHEDULE_STARTUP}
    {...props}
  />
);

export default ScheduleStartupTveButton;
