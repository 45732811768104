import React, { useState, useEffect } from 'react';

function Clock({ timeZone }) {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update every second

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  const formattedTime = currentTime.toLocaleString(undefined, {
    timeZone: timeZone,
  });

  return (
    <>
      <span style={{ fontFamily: 'Courier', fontWeight: 'bold' }}>{formattedTime}</span>
    </>
  );
}

export default Clock;
