import * as React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import BackButton from '../Components/BackButton';
import { Title, CreateButton, Button } from 'react-admin';
import TveTitle from '../Components/TveTitle';
import AdministrationButton from '../Components/AdministrationButton';
import StackReportingButton from '../Components/StackReportingButton';
import AdministrationReportingButton from '../Components/AdministrationReportingButton';

const FaqCreateButton = () => <CreateButton resource="faq" label="faqs" variant="contained" />;

const TermCreateButton = () => <CreateButton resource="term" label="terms" variant="contained" />;

const SomeOtherButton = () => <CreateButton resource="tveclasses" label="testing" variant="contained" />;

const ResourceLink = ({ label, url }) => (
  <span style={{ fontSize: '14px' }}>
    <br />
    {label}&nbsp;
    <Button
      sx={{
        fontFamily: 'Segoe UI',
        fontSize: '14px',
        pb: 0,
        pt: 0,
        pr: 0,
        textTransform: 'none',
        justifyContent: 'center',
      }}
      variant="contained"
      onClick={() => window.open(url, '_blank')}
    >
      here
    </Button>
  </span>
);

const AdministrationAndReports = () => {
  return (
    <>
      <Title title={<TveTitle pageName="Administration and Reports" />} />
      <BackButton label="Return to Class List" to="/tveclasses" />
      <hr />
      <Card>
        <CardHeader title="Administration" />
        <hr />
        <CardContent>{<AdministrationButton />}</CardContent>
      </Card>
      <hr />
      <Card>
        <CardHeader title="Reports" />
        <hr />
        <CardContent>{<><StackReportingButton /> <AdministrationReportingButton /></>}</CardContent>
      </Card>
    </>
  );
};
export default AdministrationAndReports;
