import { usePermissions } from 'react-admin';
import { CircularProgress } from '@mui/material';
import { Navigate } from 'react-router-dom';
import TveStudentDashboard from './TveStudentDashboard';

const AppDashboard = () => {
  const { isLoading, permissions } = usePermissions();

  if (isLoading) return <CircularProgress />;

  switch (permissions) {
    case 'ADMIN':
    case 'DEVELOPER':
    case 'INSTRUCTOR':
      return <Navigate replace to="/tveclasses" />;
    case 'STUDENT':
      return <TveStudentDashboard />;
    default:
      return null;
  }
};

export default AppDashboard;
