import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-admin';
import BackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const Wrapper = styled(Box)({
  display: 'grid',
  paddingTop: '10px',
});

const BackButton = ({ to, label, ...rest }) => (
  <Wrapper sx={{ justifyContent: 'center' }}>
    <Button
      sx={{ width: 220 }}
      component={Link}
      to={to}
      label={label}
      variant="contained"
      {...rest}
    >
      <BackIcon />
    </Button>
  </Wrapper>
);

export default BackButton;
