import React from 'react';
import { Create, SimpleForm, TextInput, required, maxLength } from 'react-admin';
import BackButton from '../Components/BackButton';
import TveTitle from '../Components/TveTitle';

const TveTrainingStatusCreate = () => {
  return (
    <>
      <BackButton label="Return to User Training Status List" to="/usertrainingstatuses" />
      <Create title={<TveTitle pageName="Create User Training Status" />} redirect="list">
        <SimpleForm>
          <TextInput
            source="name"
            label="User Training Status"
            fullWidth
            validate={[required(), maxLength(255)]}
          />
          <TextInput
            source="description"
            label="Description"
            fullWidth
            defaultValue=""
            validate={maxLength(255)}
          />
          <TextInput
            label={false}
            type="hidden"
            source="lookupType.lookupTypeId"
            defaultValue={3}
            InputProps={{ disableUnderline: true }}
          />
          <TextInput
            disabled
            label={false}
            type="hidden"
            source="lookupType.name"
            defaultValue="App User Training Status"
            InputProps={{ disableUnderline: true }}
          />
          <TextInput
            disabled
            label={false}
            type="hidden"
            source="lookupType.description"
            defaultValue=""
            InputProps={{ disableUnderline: true }}
          />
        </SimpleForm>
      </Create>
    </>
  );
};

export default TveTrainingStatusCreate;
