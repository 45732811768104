import React from 'react';
import { InfiniteList, Datagrid, TextField, TopToolbar, CreateButton, BulkDeleteButton } from 'react-admin';
import TveTitle from '../Components/TveTitle';

const TveAppPropertyValueListActions = () => (
  <TopToolbar>
    <CreateButton label="Add Setting" variant="contained" />
  </TopToolbar>
);

const BulkActionButtons = () => (
  <>
    <BulkDeleteButton
      sx={{
        color: 'white',
        backgroundColor: 'red',
        padding: '6px 11px',
        '&:hover': {
          backgroundColor: '#0066CC',
        },
      }}
    />
  </>
);

const TveAppPropertyValueList = () => (
  <InfiniteList
    title={<TveTitle pageName="Settings" />}
    exporter={false}
    actions={<TveAppPropertyValueListActions />}
  >
    <Datagrid bulkActionButtons={<BulkActionButtons />} rowClick="edit">
      <TextField source="tveProp.tvePropKey" label="Property (click row to edit)" />
      <TextField source="tvePropValue" label="Value" />
      <TextField source="scope" />
      <TextField source="appUser.appUserAlias" label="User" />
      <TextField source="tveProp.description" label="Description" />
    </Datagrid>
  </InfiniteList>
);

export default TveAppPropertyValueList;
