import * as React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import BackButton from '../Components/BackButton';
import { Title, Button } from 'react-admin';
import TveTitle from '../Components/TveTitle';
import { CANES_TVE_ENV } from '../Config/ReactAppConfig';

const ResourceLink = ({ label, url }) => (
  <span style={{ fontSize: '14px' }}>
    <br />
    {label}&nbsp;
    <Button
      sx={{
        fontFamily: 'Segoe UI',
        fontSize: '14px',
        pb: 0,
        pt: 0,
        pr: 0,
        textTransform: 'none',
        justifyContent: 'center',
      }}
      variant="contained"
      onClick={() => window.open(url, '_blank')}
    >
      here
    </Button>
  </span>
);

const Resources = () => {
  return (
    <>
      <Title title={<TveTitle pageName="Resources" />} />
      <BackButton label="Return to Class List" to="/tveclasses" />
      <hr />
      <Card>
        <CardHeader title="Resources" />
        <hr />
        <CardContent>
          {CANES_TVE_ENV !== 'TEST' && (
            <>
              <ResourceLink label="Access TVE Instructor Resources" url="/instructor-content/" />
              <ResourceLink label="Access TVE Student Resources" url="/student-content/" />
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};
export default Resources;
