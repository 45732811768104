import React, { useState } from 'react';
import { Data } from './FAQData';
import { Add, Remove } from '@mui/icons-material';
import BackButton from '../Components/BackButton';
import { Card, CardContent, CardHeader, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Title } from 'react-admin';
import TveTitle from '../Components/TveTitle';

const Wrap = styled(Box)({
  color: 'black',
  background: 'aliceblue',
  paddingTop: '8px',
  paddingBottom: '8px',
});

const Dropdown = styled(Box)({});

const FAQAccordion = () => {
  const [clicked, setClicked] = useState(false);
  const toggle = (index) => {
    if (clicked === index) {
      // if clicked question is already active; close it
      return setClicked(null);
    }
    setClicked(index);
  };

  return (
    <>
      <Title title={<TveTitle pageName="FAQs" />} />
      <BackButton label="Return to Help & Support" to="/help" />
      <hr />
      <Card>
        <CardHeader title="Frequently Asked Questions" />
        <hr />
        <CardContent>
          {Data.map((item, index) => {
            return (
              <>
                <Wrap onClick={() => toggle(index)} key={index}>
                  <span>
                    <div>
                      {clicked === index ? <Remove sx={{ pt: 1 }} /> : <Add sx={{ pt: 1 }} />}
                      &nbsp;
                      {item.question}
                    </div>
                  </span>
                </Wrap>
                {clicked === index ? (
                  <Dropdown>
                    <p>{item.answer}</p>
                  </Dropdown>
                ) : null}
              </>
            );
          })}
        </CardContent>
      </Card>
    </>
  );
};
export default FAQAccordion;
