import React from 'react';
import { useGetOne } from 'react-admin';
import BackButton from '../Components/BackButton';
import TveClassAppUserList from './TveClassAppUserList';
import { withLogging } from '../System/LoggingUtils';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

const TveClassAppUserListTitle = () => {
  const baseTitle = 'Manage Records In Class';
  let { classId } = useParams();

  const { data, isLoading } = useGetOne('tveclasses', { id: classId });
  if (isLoading) {
    return <CircularProgress size="2rem" color="inherit" />;
  }

  const name = `${data?.name}`;
  const track = `${data?.track}` === 'undefined' ? '' : ` - ${data?.track} track`;
  const location =
    `${data?.tveTrainingLocation?.displayName}` === 'undefined'
      ? ''
      : ` - ${data?.tveTrainingLocation?.displayName}`;
  const instructor =
    `${data?.instructor?.appUserAlias}` === 'undefined'
      ? ''
      : ` - ${data?.instructor?.appUserAlias}`;

  return (
    <span>
      {baseTitle}: {classId}&nbsp;-&nbsp;
      <span style={{ fontFamily: 'Tahoma' }}>{name}</span>
      <span style={{ fontFamily: 'Tahoma', fontWeight: 'bold' }}>{track}</span>
      <span style={{ fontFamily: 'Monospace' }}>{location}</span>
      <span style={{ fontFamily: 'Courier' }}>{instructor}</span>
    </span>
  );
};

const TveClassManage = () => {
  let { classId } = useParams();

  return (
    <>
      <BackButton label="Return to Classes List" to="/tveclasses" />
      {/**
       * Props in this component call are specific to the "Managing a class" page,
       *     or  emulating what would be passed in if it was on the list property of a Resource component.
       * Props in the TveClassAppUserList component are specific to how we would want the class user list to work,
       *     whether in the manage page or as the list of a resource.
       */}
      <TveClassAppUserList
        title={<TveClassAppUserListTitle />}
        resource="tveclassappusers"
        filter={{ tveclassid: parseInt(classId) }}
        hasCreate={true}
        hasEdit={true}
        hasShow={false}
        hasList={false}
      />
    </>
  );
};

export default withLogging(TveClassManage);
