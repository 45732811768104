import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import image from '../img/ship_background.webp';
import queryString from 'query-string';
import { Button, useLogin, email } from 'react-admin';
import { MenuItem, Select, Typography, Box, TextField, Snackbar, SnackbarContent } from '@mui/material';
import { LOGIN_ROLES, LOGIN_ORGS, registerRedirect, loginRedirect } from '../Config/AuthProvider';
import LockIcon from '@mui/icons-material/LockTwoTone';
import { styled } from '@mui/material/styles';
import LoginIcon from '@mui/icons-material/LockOpen'
import TryAgainIcon from '@mui/icons-material/Replay';
import RegisterIcon from '@mui/icons-material/Add';
import ReturnIcon from '@mui/icons-material/KeyboardBackspace';

const SectionWrapper = styled(Box)({
  backgroundColor: 'white',
  marginTop: 5,
  marginLeft: 100,
  marginRight: 100,
});

const TextWrapper = styled(Box)({
  marginTop: 4,
  marginBottom: 4,
  marginLeft: 10,
  marginRight: 40,
});

const LoginTitle = () => {
    return (
      <div style={{ fontFamily: 'Roboto', textAlign: 'center', color: '#3f51b5' }}>
        CANES Training Virtual Environment
      </div>
    );
  };

  const WarningMessage = (isLoading) => {
    return (
      <div style={{ fontFamily: 'Roboto', textAlign: 'center', color: '#3f51b5' }}>
        <div
          style={{
            fontSize: 'smaller',
            fontFamily: 'Arial',
            color: 'black',
            textAlign: 'center',
            marginTop: 10,
            marginBottom: 10,
            marginLeft: 80,
            marginRight: 80,
          }}
        >
          WARNING
          <hr />
        </div>
        <div
          style={{
            fontSize: 'smaller',
            fontFamily: 'Arial',
            color: 'black',
            textAlign: 'center',
            marginLeft: 80,
            marginRight: 80,
          }}
        >
          <p style={{ fontFamily: 'Roboto', textAlign: 'left' }}>
            You are accessing a U.S. Government (USG) Information System (IS) that is provided for
            USG-authorized use only.
            <br />
            By using this IS (which includes any device attached to this IS), you consent to the
            following conditions:
            <br />
            The USG routinely intercepts and monitors communications on this IS for purposes
            including, but not limited to, penetration testing, COMSEC monitoring, network
            operations and defense, personnel misconduct (PM), law enforcement (LE), and
            counterintelligence (CI) investigations.
            <br />
            At any time, the USG may inspect and seize data stored on this IS.
            <br />
            Communications using, or data stored on, this IS are not private, are subject to routine
            monitoring, interception, and search, and may be disclosed or used for any
            USG-authorized purpose.
            <br />
            This IS includes security measures (e.g., authentication and access controls) to protect
            USG interests--not for your personal benefit or privacy.
            <br />
            Notwithstanding the above, using this IS does not constitute consent to PM, LE or CI
            investigative searching or monitoring of the content of privileged communications, or
            work product, related to personal representation or services by attorneys,
            psychotherapists, or clergy, and their assistants. Such communications and work product
            are private and confidential. See User Agreement for details.
            <br />
            By clicking "Agree" button below, you acknowledge this warning to the system rules of
            behavior and acceptable use policies.
          </p>
        </div>
      </div>
    );
  };
  

const LoginPageTemplate = ({
    showProgress,
    showButton,
    children,
    buttonClick,
    buttonLabel,
    ButtonIcon
}) => (
      <div style={{ height: '100vh', backgroundImage: `url(${image})`, backgroundSize: "cover"}}>
      {/* return <MuiThemeProvider theme={this.props.theme}> */}
        {/* <StigMessage /> */}
        {/* <PageTitle /> */}
        <wbr />
        <div>
        {/* <FixedWidthCardContent> */}
          {/* {showProgress && <CircularProgress />} */}
          <p>{showProgress && 'Loading'}</p>
          {children}
        {/* </FixedWidthCardContent> */}
        </div>

        <div
          style={{
            fontSize: 'larger',
            fontFamily: 'Roboto',
            textAlign: 'center',
            color: '#3f51b5',
            backgroundColor: 'white',
            marginTop: 50,
            marginLeft: 100,
            marginRight: 100,
          }}
        >
        <LockIcon style={{marginTop: 10}}/>
        <LoginTitle/>
        <WarningMessage />
        {showButton &&
        <div>
        {/* <StyledCardActions style={{textAlign: 'center'}}> */}
            {/* <LoginButton */}
            <Button sx={{backgroundColor: '#2196f3', color: 'white', "&:hover": { backgroundColor: "#0a6ebd" }}}
              onClick={buttonClick}
              label={buttonLabel}
              color="secondary"
              variant="outlined"
            >
              <ButtonIcon />
            </Button>
            {/* </LoginButton> */}
          {/* </StyledCardActions> */}
          </div>
        }
          <wbr/>
        </div>
      {/* </MuiThemeProvider> */}
    </div>
);


const LoginPageReturnLoginPageTemplate = ({
    children,
    buttonLabel="Return To Login",
    ButtonIcon=ReturnIcon
}) => {
    const navigate = useNavigate()
    return <LoginPageTemplate
        showButton={true}
        buttonClick={() => navigate('/login')}
        buttonLabel={buttonLabel}
        ButtonIcon={ButtonIcon}
    >
        {children}
    </LoginPageTemplate>
}

const CacFailedLoginPage = () => (
    <LoginPageReturnLoginPageTemplate
        buttonLabel='Try Again'
        ButtonIcon={TryAgainIcon}
    >
      <SectionWrapper sx={{ border: 2, borderColor: 'red' }}>
        <TextWrapper>
          <Typography variant="regPageText">We are unable to read your CAC.</Typography>
          <Typography variant="regPageText">Please try removing and reinserting your CAC card and before logging in again.</Typography>
          <Typography variant="regPageText">If the error persists, please contact the system administrator.</Typography>
        </TextWrapper>
      </SectionWrapper>
    </LoginPageReturnLoginPageTemplate>
)

const AccountDisabledLoginPage = () => (
    <LoginPageReturnLoginPageTemplate
        buttonLabel='Try Again'
        ButtonIcon={TryAgainIcon}
    >
      <SectionWrapper sx={{ border: 2, borderColor: 'red' }}>
        <TextWrapper>
          <Typography variant="regPageText">Your account is not activated.</Typography>
          <Typography variant="regPageText">If you’ve just registered, please have your CANES Instructor Approve and Activate your account.</Typography>
        </TextWrapper>
      </SectionWrapper>
    </LoginPageReturnLoginPageTemplate>
)
    
const RegisterPage = () => {
    const [roleId, setRoleId ] = useState(1)
    const [orgId, setOrgId ] = useState(1)
    const [email, setEmail ] = useState('')
    const [emailError, setEmailError] = useState(false);
    const [phoneNumber, setPhoneNumber ] = useState('')

    // use a regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleEmailChange = (e) => {
      const newEmail = e.target.value;
      setEmail(newEmail);
      setEmailError(!emailRegex.test(newEmail));
    };

    const handlePhoneNumberChange = (event) => {
      // remove non-numeric characters using regex
      const numericValue = event.target.value.replace(/\D/g, '');
      setPhoneNumber(numericValue);
    };

    const isRegisterButtonDisabled = roleId !== 1 && !(email.trim() && phoneNumber.trim() && emailRegex.test(email));

    return <LoginPageTemplate
        showButton={!isRegisterButtonDisabled}
        buttonClick={() => registerRedirect(roleId, orgId, email, phoneNumber,)}
        buttonLabel={"Register"}
        ButtonIcon={RegisterIcon}
    >
      <SectionWrapper sx={{ border: 2, borderColor: 'red' }}>
        <TextWrapper>
          <Typography variant="regPageText">
            You are not authorized to access this system.</Typography>
          <Typography variant="regPageText">
            If you have not yet requested access, register by selecting your expected role. If you are selecting INSTRUCTOR role, you will be prompted to select an organization. After making selection(s), click the 'Register' button below.
          </Typography>
          <div style={{ display:'flex', justifyContent:'center', paddingTop:2}}>
            <Select
              size="small"
              label="Role"
              value={roleId}
              onChange={e => setRoleId(e.target.value)}
            >
              {LOGIN_ROLES.map(role => <MenuItem key={role.roleId} value={role.roleId}>{role.name}</MenuItem>)}
            </Select>
          </div>
          {roleId !== 1 &&  
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 8 }}>
            <Select
              size="small"
              label="Organization"
              value={orgId}
              onChange={e => setOrgId(e.target.value)}
            >
              {LOGIN_ORGS.map(org => <MenuItem key={org.orgId} value={org.orgId}>{org.name}</MenuItem>)}
            </Select>
          </div> }
          {roleId !== 1 && orgId && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <TextField
                size="small"
                label="Email"
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
                error={emailError}
                helperText={emailError ? 'Please enter a valid email address' : ''}
              />
              <Typography variant="caption" style={{ color: 'red', marginTop: 0 }}>
                {email.trim() ? '' : 'Required'}
              </Typography>
             </div>
            )}
          {roleId !== 1 && orgId && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <TextField
                size="small"
                label="Phone Number"
                variant="outlined"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
             <Typography variant="caption" style={{ color: 'red', marginTop: 0 }}>
               {phoneNumber.trim() ? '' : 'Required'}
             </Typography>
           </div>
          )}
        </TextWrapper>
      </SectionWrapper>
    </LoginPageTemplate>
}

const RegistrationSuccessPage = () => (
  <LoginPageReturnLoginPageTemplate>
    <SectionWrapper sx={{ border: 2, borderColor: 'green' }}>
      <TextWrapper>
        <Typography variant="regPageText">Your request for access has been submitted.</Typography>
        <Typography variant="regPageText">You will be able to access the system once your request is approved and your account is enabled.</Typography>
      </TextWrapper>
    </SectionWrapper>
  </LoginPageReturnLoginPageTemplate>
)

const RegistrationErrorPage = () => (
    <LoginPageReturnLoginPageTemplate
        buttonLabel='Try Again'
        ButtonIcon={TryAgainIcon}
    >
      <SectionWrapper sx={{ border: 2, borderColor: 'red' }}>
        <TextWrapper>
          <Typography variant="regPageText">We were unable to process your registration request.</Typography>
          <Typography variant="regPageText">If you have previously requested access, try logging in again.</Typography>
          <Typography variant="regPageText">If the error persists, please contact the system administrator.</Typography>
        </TextWrapper>
      </SectionWrapper>
    </LoginPageReturnLoginPageTemplate>
)

//TODO when do we show the warning - looks like current function is to show it every time.
        // case ENABLED:
        //   if (!isLoading) {
        //     elements.message = <WarningMessage />
        //   }
const LoginWithCacPage = ({children}) => {
  
  const [isLogoutMessageOpen, setLogoutMessageOpen] = React.useState(true);

  const handleLogoutMessageClose = () => {
    setLogoutMessageOpen(false);
  };

  return (
    <LoginPageTemplate
        showButton={true}
        buttonClick={() => loginRedirect()}
        buttonLabel={"Accept and Login with CAC"}
        ButtonIcon={LoginIcon}
    >
        {children}
      <Snackbar
        open={isLogoutMessageOpen}
        autoHideDuration={2000}
        onClose={handleLogoutMessageClose}
        style={{
          position: 'fixed',
          left: '50%',
          top: '60%',
          transform: 'translate(-50%, -50%)',
        }}>
          <SnackbarContent sx={{ justifyContent: 'center' }} message="You are logged out"/>
      </Snackbar>
    </LoginPageTemplate>
  )
};

const LoginPage = () => {
    const login = useLogin();
    const location = useLocation();
  
    const { auth_status, jwt, register_status } = queryString.parse(location.search)

    if (auth_status !== undefined && auth_status === "200" && jwt !== undefined) {
        console.log('calling auth provider login')
        login({token: jwt})
    }

    //backend did not get a valid cac value
    if (auth_status !== undefined && auth_status === "400") {
        return <CacFailedLoginPage />
    }

    //Not registered
    if (auth_status !== undefined && auth_status === "401") {
        return <RegisterPage />
    }

    //account is disabled -- auth status 403
    if (auth_status !== undefined && auth_status === "403") {
        return <AccountDisabledLoginPage />
    }

    //registration successful
    if (register_status !== undefined && register_status === "200") {
        return <RegistrationSuccessPage />
    }

    //registration failed
    if (register_status !== undefined && register_status !== "200")
        return <RegistrationErrorPage />

    return <LoginWithCacPage />
}
  
  export default LoginPage;