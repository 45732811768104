import React from 'react';
import {
  Create,
  SimpleForm,
  ReferenceInput,
  TextInput,
  SelectInput,
  usePermissions,
} from 'react-admin';
import BackButton from '../Components/BackButton';
import StoredUser from '../System/StoredUser';

const formDefaultValues = () => ({
  tveProp: { tveAppPropertyId: 1 },
  scope: 'User',
  appUser: { appUserId: new StoredUser().load().appUser.appUserId },
});

const TveAppPropertyValueCreate = () => {
  const { permissions } = usePermissions();
  return (
    <>
      <BackButton label="Return to Configuration List" to="/tveapppropvalues" />
      <Create redirect="list">
        <SimpleForm defaultValues={formDefaultValues()}>
          <ReferenceInput
            source="tveProp.tveAppPropertyId"
            reference="application_config_properties"
            fullWidth
          >
            <SelectInput label="Property" optionText="tvePropKey" />
          </ReferenceInput>

          <TextInput source="tvePropValue" label="Value" fullWidth />

          {permissions === 'ADMIN' &&
            ((
              <SelectInput
                source="scope"
                optionText="optionText"
                optionValue="optionValue"
                choices={['User', 'System'].map((it) => ({ optionText: it, optionValue: it }))}
                fullWidth
              />
            ),
            (
              <ReferenceInput
                source="appUser.appUserId"
                reference="appusers"
                label="User"
                fullWidth
              >
                <SelectInput label="User" optionText="appUserAlias" />
              </ReferenceInput>
            ))}
        </SimpleForm>
      </Create>
    </>
  );
};

export default TveAppPropertyValueCreate;
