import ScheduleIcon from '@mui/icons-material/Schedule';
import UnscheduleIcon from '@mui/icons-material/Cancel';
import StoredUser from '../System/StoredUser'

const SCHEDULE_PAUSE      = 'SCHEDULE_PAUSE'
const UNSCHEDULE_PAUSE    = 'UNSCHEDULE_PAUSE'
const SCHEDULE_STARTUP      = 'SCHEDULE_STARTUP'
const UNSCHEDULE_STARTUP    = 'UNSCHEDULE_STARTUP'

const canSchedulePauseTve = () => ["ADMIN","INSTRUCTOR"].includes(new StoredUser().getPermissions())
const canUnschedulePauseTve = () => ["ADMIN","INSTRUCTOR"].includes(new StoredUser().getPermissions())
const canScheduleStartupTve = () => ["ADMIN","INSTRUCTOR"].includes(new StoredUser().getPermissions())
const canUnscheduleStartupTve = () => ["ADMIN","INSTRUCTOR"].includes(new StoredUser().getPermissions())

export {
    UNSCHEDULE_PAUSE,
    UnscheduleIcon,
    SCHEDULE_PAUSE,
    ScheduleIcon,
    SCHEDULE_STARTUP,
    UNSCHEDULE_STARTUP,

    canSchedulePauseTve,
    canUnschedulePauseTve,
    canScheduleStartupTve,
    canUnscheduleStartupTve
}
