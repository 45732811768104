import React from 'react';
import { InfiniteList, TextField, Datagrid, CreateButton, TopToolbar, BulkDeleteButton } from 'react-admin';
import TveTitle from '../Components/TveTitle';

const TveClassStatusListActions = () => (
  <TopToolbar>
    <CreateButton label="add class status" variant="contained" />
  </TopToolbar>
);

const BulkActionButtons = () => (
  <>
    <BulkDeleteButton
      sx={{
        color: 'white',
        backgroundColor: 'red',
        padding: '6px 11px',
        '&:hover': {
          backgroundColor: '#0066CC',
        },
      }}
    />
  </>
);

const TveClassStatusList = () => (
  <InfiniteList title={<TveTitle pageName="Class Statuses" />} actions={<TveClassStatusListActions />}>
    <Datagrid bulkActionButtons={<BulkActionButtons />} rowClick="edit">
      <TextField label="Class Status" source="name" />
      <TextField source="description" />
    </Datagrid>
  </InfiniteList>
);
export default TveClassStatusList;
