import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  maxLength,
  Toolbar,
  SaveButton,
  DeleteButton,
} from 'react-admin';
import BackButton from '../Components/BackButton';
import TveTitle from '../Components/TveTitle';

const CustomToolbar = () => (
  <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <SaveButton />
    <DeleteButton
      variant="contained"
      sx={{
        color: 'white',
        backgroundColor: 'red',
        padding: '6px 11px',
        '&:hover': {
          backgroundColor: '#0066CC',
        },
      }}
    />
  </Toolbar>
);

const TveStatusEdit = () => {
  return (
    <>
      <BackButton label="Return to TVE Status List" to="/tvestatuses" />
      <Edit title={<TveTitle pageName="Edit TVE Status" />}>
        <SimpleForm toolbar={<CustomToolbar />}>
          <TextInput
            label="TVE Status"
            source="name"
            fullWidth
            validate={[required(), maxLength(255)]}
          />
          <TextInput source="description" fullWidth validate={maxLength(225)} />
        </SimpleForm>
      </Edit>
    </>
  );
};
export default TveStatusEdit;
