import React from 'react';
import { Layout, AppBar, UserMenu, usePermissions, Logout } from 'react-admin';
import { Typography, MenuItem, ListItemText, ListItemIcon } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import { TveMenu } from './TveMenu';
import { CANES_TVE_ENV } from '../Config/ReactAppConfig';
import { styled } from '@mui/material/styles';

const ShowRole = () => {
  const { permissions } = usePermissions();
  return (
    <MenuItem>
      <ListItemIcon sx={{ minWidth: 5 }}>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText>{permissions}</ListItemText>
    </MenuItem>
  );
};

const ShowEnvironment = () => {
  return (
    <MenuItem>
      <ListItemIcon sx={{ minWidth: 5 }}>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText>{CANES_TVE_ENV}</ListItemText>
    </MenuItem>
  );
};

const TveUserMenu = (props) => (
  <UserMenu {...props}>
    <ShowRole />
    <ShowEnvironment />
    <Logout />
  </UserMenu>
);

const TopStripe = styled('div')`
  background-color: #008000;
  height: 6px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

const TveAppBar = (props) => (
  <AppBar sx={{ color: 'white', backgroundColor: 'black' }} {...props} userMenu={<TveUserMenu />}>
    <TopStripe />
    <Typography flex="1" variant="h6" id="react-admin-title"></Typography>
  </AppBar>
);

export const TveLayout = (props) => <Layout {...props} appBar={TveAppBar} menu={TveMenu} />;
