import React from 'react';
import TveButton from './TveButton';
import { COMPLETE, CompleteIcon } from '../Config/TveActions';

const CompleteStudentTrainingButton = (props) => (
  <TveButton
    label="Complete Training"
    color="primary"
    icon={<CompleteIcon />}
    action={COMPLETE}
    confirm={true}
    fromTveStatuses={['Not Created', 'Created', 'Running', 'Paused', 'Deleted']}
    fromTrainingStatuses={['Not Started', 'In Progress']}
    fromCanesVersions="ALL"
    {...props}
  />
);

export default CompleteStudentTrainingButton;
